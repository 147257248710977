const ROUTES = {

    // Common Routes

    LOGIN: "/apps/login",
    BD_LOGIN: "/document/login",
    SIGNUP: "/apps/signUp",
    PROFILE: "/apps/profile",
    // TENDER_GRID: "/tendergrid",
    FORGOT_PASSWORD: "/apps/forgot-password",
    WALLET: "/apps/my-wallet",
    CHECKOUT: '/apps/checkout',
    CONDITION: 'apps/conditions',

    //TenderGrid Routes

    // TENDER_DETAILS: "/tendergrid/tender-details/:id",
    // CONTACT: "/tendergrid/contact-us",
    // ABOUT_US: "/tendergrid/aboutus",
    // CLIENT: "/tendergrid/client",
    // TENDER_LIST: "/tendergrid/tender-list",
    // TENDER_LIST_ID: "/tendergrid/tender-list/:type/:id",
    // TENDER_LIST_TYPE: "/tendergrid/tender-list/:type/:id",
    // MY_FAVOURITE: "/tendergrid/my-favourite",
    // LAST_HOUR: "/tendergrid/last-24-hours",
    // ARCHIVE: "/tendergrid/archive",
    // RECOMMENDED: "/tendergrid/recommended",
    // SUBSCRIBE_ARCHIVE: "/tendergrid/subscribe-archive",
    // SUBSCRIBE_LIVE: "/tendergrid/subscribe-live",
    // TENDER_BOUGHT: "/tendergrid/purchased-tenders",
    // CATEGORY_LIST: "/tendergrid/categorieslist",
    // CITY_WISE_TENDER: "/tendergrid/citywisetender",
    // ORGANIZATION: "/tendergrid/authority-wise-tenders",
    // SUBSCRIBE: "/tendergrid/plans",
    // STATE_WISE_TENDER: "/tendergrid/state-wise-tenders",
    // CATEGORY_WISE_TENDER: "/tendergrid/sector-wise-tenders",
    // SETTING: "/tendergrid/setting",
    // MY_WISHLIST: "/tendergrid/mywishlist",
    // FEEDBACK: "/tendergrid/feedback",
    // SUPPORT: "/tendergrid/support",
    // WEBSITE: "/tendergrid/website",
    // SITEMAP: "/tendergrid/sitemap",
    //................

    // statGrid Routes

    // STAT_LOGIN:"/statgrid/login",
    // STAT_SIGNUP:"/statgrid/signup",
    // STAT_FORGOT_PASSWORD : "/statgrid/forgot-password",
    // // main routs 
    // STATDASHBOARD:"/statgrid/dashboard",

    // COMPANIES: "/statgrid/companies",
    // COMPANY_DETAIL: "/statgrid/company",

    // PERFORMANCE:"/statgrid/performance",
    // PERFORMANCE_DETAIL: "/statgrid/performance",

    // RESULTS: "/statgrid/results",
    // RESULT_DETAIL: "/statgrid/result",

    // MARKET_SHARE:"/statgrid/market-share",

    // TENDER_CALENDER: "/statgrid/tender-calender",
    // REMINDER: "/statgrid/reminder",
    // SAVED_SEARCH: "/statgrid/saved-search",
    // COMPANIES: "/statgrid/companies",
    // COMPANY_DETAILS: "/statgrid/company/:id",
    // RESULT_LIST: "/statgrid/result-list",
    // RESULT_DETAILS: "/statgrid/result/:id",
    // STATE_GRID_ROOT: "/statgrid/",
    // STATS: "/statgrid/stats",
    // STATGRID_DASHBOARD: "/statgrid",
    // STATGRID_CALENDER: "/statgrid/statgrid-calender",
    // COMPANY_LIST: "/statgrid/companylist",
    // // STATGRID_HOME:"/statgrid/statgridhome",
    // STATGRID_REMINDER: "/statgrid/reminder",
    // STATGRID_SAVETENDER: "/statgrid/savetender",
    // // STATGRID_HOME:"/statgrid/statgridhome",
    // MARKET_SHARE:"/statgrid/market-share",
    // MARKET_SHARE_DETAILS:"/statgrid/marketsharedetails",
    // STATGRID_HOME:"/statgrid/statgridhome",
    // STAT_TENDER_RESULT: "/statgrid/tenderresult",

    // COMPANY_DASHBOARD: "/statgrid/companydashboard",
    // RESULT_DETAILS_PAGE: "/statgrid/resultdetails",
    // PERFORMANCE: "/statgrid/performance",


    //....................

    //BidGrid Routes
    PREPAREFACTSHEET: "/document/PrepareFactSheet",
    MEETING: "/document/meetingschedules",
    DASHBOARD: "/document/dashboard",
    TASK_MANAGER: "/document/taskmanager",
    Task_assigine: "/document/taskassigine",
    MODELS: "/document/model",
    ADDUNIT: "/document/master/addUnit",
    JOBTITLE: "/document/master/jobtitle",
    PREFIX: "/document/master/prefix",
    ROLE: "/document/master/role",
    DESIGNATION: "/document/master/designation",
    TeamRequisitionDesignation: "/document/master/team-rq-designation",
    TeamRequisitionDesignationCategory: "/document/master/team-rq-category",

    CURRENCY: "/document/master/currency",
    LANGUAGE: "/document/master/language",
    TIMEZONE: "/document/master/timezone",
    ASSIGNSECTOR: "/document/master/assignsector",
    KEYWORD: "/document/master/keyword",
    COMPANY: "/document/AddTender/company",
    LOGO: "/document/master/Logo",
    SUBSECTOR: "/document/master/subsector",
    TENDER_STATUS: "/document/master/tenderstatus",
    BD_CLIENT: "/document/master/client",
    CONSORTIUM: "/document/master/consortium",
    NATIONALITY: "/document/master/nationality",
    GENDER: '/document/master/gender',
    DEPARTMENT_MANAGE: "/document/master/department",
    TENDER_PHASE: "/document/master/phase",
    TENDER_SCOPE: "/document/master/tenderscope",
    FUNDING_AGENCY: "/document/master/fundingagency",
    COUNTRYLIST: "/document/master/countrylist",
    STATELIST: "/document/master/statelist",
    CITYLIST: "/document/master/citylist",
    CONTINENTLIST: "/document/master/continentlist",
    REGIONLIST: "/document/master/regionlist",
    EMPLOYEE: "/document/master/employee",
    EMPLOYEE_STATUS: "/document/master/employee_status",
    BLOODGROUP: "/document/master/bloodgroup",
    TENDER_SERVICES: "/document/master/tenderservies",
    WISHLIST: "/document/wishlist",
    WORKLOAD_DISTRIBUTION: "/document/workdistribution",
    CALENDAR: "/document/calendar",
    LIVE_TENDERS: "/document/livetender",
    Personal_reminder: "/document/reminder",
    BD_PROFILE: "/document/profile",
    BD_EMP_PROFILE: "/document/profile/:id",
    New_Tenders: "/document/newtender",
    New_assigne: "/document/newassigne",
    document_share: "/document/document",
    PROSPECTIVE_TENDER: "/document/prospectivetender",
    CHATBOAT: "/document/chatboat",
    BD_TENDERDETAILS: "/document/bdtenderdetails/:id",
    ConFiguration: '/document/Configuration/configuration',
    EMPLOYEELIST: '/document/pages/employeelist',
    BDREQUEST: "/document/pages/bdtenderdetails",
    MIS:"/document/mis",
    // BD_TENDERDETAILS_id: "/bidgrid/bdtenderdetails/:id",

    //................

    // COMPANY_LIST: "/companylist",
    STATSGRID_INFO: "/apps/statgridinfo",
    DISCLAIMER: "/apps/disclaimer",
    TERMS_POLICY: "/apps/privacy-policy",
    CONDITIONS: "/apps/terms-and-conditions",
    SUB_SIGNUP: "/subdivision/signup",
    USERLIST:"/statgrid/userlist",
    ADDUSER:"/statgrid/adduser",
    PROFILES:"/statgrid/profile",
    ROLES:"/statgrid/roles",

    ERROR: "/error",
    ROOT: "/",
    WILDCARD: "*"
}

export default ROUTES