const STATROUTES = {
    PLACEHOLDER: "statgrid", // placeholder for navigation


    STAT_LOGIN: "login",
    STAT_SIGNUP: "signup",
    STAT_FORGOT_PASSWORD: "forgot-password",
    // main routs 
    STATDASHBOARD: "dashboard",

    COMPANIES: "companies",
    COMPANY_DETAIL: "company",

    PERFORMANCE: "performance",
    PERFORMANCE_DETAIL: "performance",

    RESULTS: "results",
    RESULT_DETAIL: "result",

    MARKET_SHARE: "market-share",

    TENDER_CALENDER: "tender-calender",
    REMINDER: "reminder",
    SAVED_SEARCH: "saved-search",
    USERLIST: "userlist",
    ADDUSER: "adduser",
    PROFILES: "profile",
    ROLES: "roles",
    // end here 


    // TODO : unused old routs do as you wish

    // STATE_GRID_ROOT: "/",
    // STATS: "/statgrid/",  // extra Routess
    // STATSGRID_INFO: "/statgridinfo",  // extra Routes
    // STATGRID_DASHBOARD:"/statgriddashboard",
    // COMPstatgridcalenderANY_DASHBOARD: "/companydashboard",
    // // STATGRID_HOME:"/statgridhome",

    // MARKET_SHARE_DETAILS:"/marketsharedetails",
    // // STATGRID_HOME:"/statgridhome",
    // COMPANY_DASHBOARD:"companydashboard",
    // STAT_TENDER_RESULT: "/tenderresult",

    // // COMPANY_LIST:"/companylist",
    // RESULT_DETAILS_PAGE:"/resultdetails",

    // TODO : end here
}

export default STATROUTES