// @ts-nocheck
import Footer from 'Includes/Footer';
import Header from 'Includes/Header';
import { requestForToken } from './firebase';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import LoginPopupModal from 'Tendergrids/components/modal/LoginPopupModal';
import DropdownValues from './Includes/DropdownValues';
import { useEffect, useState } from 'react';
import ROUTES from "Constants/Routes"
import Error404 from 'common/pages/Error404/Error404';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { NavBarMenu } from 'Services/common/navbar/NavBarMenu';
import { useDispatch } from 'react-redux';
import COMROUTES from 'common/router/comRoutes';
import UnderConstruction from 'UnderConstruction/UnderConstruction';
import { dynamicMenuAction } from 'Redux/actions/common/DropdownAction';
import { useNavigate } from 'react-router-dom';

const CommonApp = lazy(() => import('common/CommonApp'));
// const TenderGridApp = lazy(() => import('Tendergrids/TenderGridApp'));
const StatgridApp = lazy(() => import('Statgrid/StatgridApp'));
const BidGridApp = lazy(() => import('BidGrid/BidGridApp'));
const CompanyVisitor = lazy(() => import('companyVisitor'));

const EmptyComponent = () => null;

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [openLogin, setOpenLogin] = useState(false)
  const [openBidLogin, setOpenBidLogin] = useState(false)
  const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
  const { userData } = useSelector((state) => state.loginData)
  const currentDate = new Date();
  const { bidgridData } = useSelector((state) => state.loginData)
  const { statgridData } = useSelector((state) => state.loginData)
  const currentYear = currentDate.getFullYear();
  const startYear = currentYear - (currentDate.getMonth() < 3 ? 1 : 0);
  const endYear = startYear + 1;
  const isInitialized = localStorage.getItem('isInitialized');

  const financialYear = `${startYear}-${endYear}`;
  localStorage.setItem('fin_year', financialYear)
  const path = window.location.pathname;
  const dispatch = useDispatch()


  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      <ToastContainer position="bottom-center" autoClose={1500} />

      <Router>

        <DropdownValues />
        <Routes>
          <Route path='/' element={<UnderConstruction />} />

          <Route path="/document/*" element={<Suspense fallback={<div></div>}><BidGridApp /></Suspense>} />
          {/* <Route path="/company-visitor/*" element={<Suspense fallback={<div></div>}><CompanyVisitor /></Suspense>} /> */}
          <Route path='/error' element={<Error404 />} />
          <Route path="*" element={<Navigate to="/error" replace />} />
        </Routes>
    
      </Router>
    </>
  );
}


export default App;
